import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { get } from "lodash"
import { Link, withPrefix } from "../../utils"

import { Container } from "reactstrap"
import { GatsbyImage } from "gatsby-plugin-image"

const Topbar = props => {
  const {
    strapiGlobal: {
      Header: { links, logo },
    },
  } = useStaticQuery(query)

  const [isOpen, setOpen] = useState(false)
  const [pathname, setPathName] = useState("")

  useEffect(() => {
    setPathName(window.location.pathname)
  })

  const toggleLine = () => {
    setOpen(v => !v)
  }

  return (
    <React.Fragment>
      <header id="topnav" className="defaultscroll sticky">
        <Container>
          <div>
            <Link className="logo" to="/">
              <GatsbyImage
                image={logo.localFile.childImageSharp.gatsbyImageData}
                alt={"BetterCapital.us"}
                className="bc-nav-logo"
              />
            </Link>
          </div>
          {(() => {
            return (
              <>
                <div className="buy-button ">
                  <Link
                    to="https://my.bettercapital.us"
                    target="_blank"
                    rel="noopener noreferrer"
                    id="signUpButton"
                    className="btn btn-primary"
                  >
                    Sign Up
                  </Link>
                </div>
                <div className="buy-button">
                  <Link
                    to="https://my.bettercapital.us"
                    target="_blank"
                    rel="noopener noreferrer"
                    id="logInButton"
                    className="btn btn-light"
                  >
                    Log In
                  </Link>
                </div>
              </>
            )
          })()}
          <div className="menu-extras">
            <div className="menu-item">
              <Link
                to="#"
                onClick={toggleLine}
                className={isOpen ? "navbar-toggle open" : "navbar-toggle"}
              >
                <div className="lines">
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </Link>
            </div>
          </div>

          <div id="navigation" style={{ display: isOpen ? "block" : "none" }}>
            <ul className="navigation-menu" id="top-menu">
              {links.map((action, action_idx) => {
                return (
                  <li
                    key={action_idx}
                    className={
                      pathname.startsWith(get(action, "url", null))
                        ? "active"
                        : ""
                    }
                  >
                    <Link
                      to={withPrefix(get(action, "url", null))}
                      {...(get(action, "newWindow", null)
                        ? { target: "_blank", rel: "noopener" }
                        : null)}
                    >
                      {get(action, "label", null)}
                    </Link>
                  </li>
                )
              })}
            </ul>
            <div className="buy-menu-btn d-none justify-content-between">
              <Link
                to="https://my.bettercapital.us"
                target="_blank"
                rel="noopener noreferrer"
                className="btn btn-primary"
                id="signUpButton"
              >
                Sign Up
              </Link>

              <Link
                to="https://my.bettercapital.us"
                target="_blank"
                rel="noopener noreferrer"
                id="logInButton"
                className="ml-2 btn btn-light"
              >
                Log In
              </Link>
            </div>
            <div className="buy-button d-none"></div>
          </div>
        </Container>
      </header>
    </React.Fragment>
  )
}

const query = graphql`
  query {
    strapiGlobal {
      Header {
        logo {
          localFile {
            childImageSharp {
              gatsbyImageData(height: 24)
            }
            publicURL
          }
        }
        has_nav
        links {
          label
          newWindow
          url
        }
      }
    }
  }
`
export default Topbar
