import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import { markdownify } from "../../utils"

const SEO = ({ seo = {} }) => {
  const { strapiGlobal } = useStaticQuery(query)
  const { defaultSeo, siteName, favicon } = strapiGlobal

  // Merge default and page-specific SEO values
  const fullSeo = { ...defaultSeo, ...seo }

  const getMetaTags = () => {
    const tags = []

    if (fullSeo.metaTitle) {
      tags.push(
        {
          property: "og:title",
          content: fullSeo.metaTitle,
        },
        {
          name: "twitter:title",
          content: fullSeo.metaTitle,
        }
      )
    }
    if (fullSeo.metaDescription) {
      tags.push(
        {
          name: "description",
          content: fullSeo.metaDescription,
        },
        {
          property: "og:description",
          content: fullSeo.metaDescription,
        },
        {
          name: "twitter:description",
          content: fullSeo.metaDescription,
        }
      )
    }

    if (fullSeo.shareImage) {
      const imageUrl =
        (process.env.GATSBY_ROOT_URL || "http://localhost:8000") +
        fullSeo.shareImage.localFile.publicURL
      tags.push(
        {
          name: "image",
          content: imageUrl,
        },
        {
          property: "og:image",
          content: imageUrl,
        },
        {
          name: "twitter:image",
          content: imageUrl,
        }
      )
    }
    tags.push({
      property: "og:type",
      content: fullSeo.contentType || "website",
    })
    tags.push({ name: "twitter:card", content: "summary_large_image" })

    return tags
  }

  const metaTags = getMetaTags()
  let schemaJSON = fullSeo.schemaJSON
  if (!schemaJSON) {
    schemaJSON = {
      "@context": "https://schema.org",
      "@type": fullSeo.contentType,
      author: fullSeo.author,
      name: fullSeo.metaTitle,
      image: fullSeo.shareImage.localFile.publicURL,
    }
  } else {
    schemaJSON.author = fullSeo.author
    schemaJSON.image = fullSeo.shareImage.localFile.publicURL
  }
  // Strapi converts the "@" to "_"
  let schemaStr = JSON.stringify(schemaJSON)
  schemaStr = schemaStr.replace("_type", "@type")
  schemaStr = schemaStr.replace("_context", "@context")

  return (
    <Helmet
      title={fullSeo.metaTitle}
      titleTemplate={`%s | ${siteName}`}
      link={[
        {
          rel: "icon",
          href: favicon.publicURL,
        },
      ]}
      script={[]}
      meta={metaTags}
    >
      <img
        height="1"
        width="1"
        style="display:none"
        src="https://www.facebook.com/tr?id=288796526189364&ev=PageView&noscript=1"
      />
      <script async type="application/ld+json">
        {schemaStr}
      </script>
    </Helmet>
  )
}

export default SEO

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  contentType: PropTypes.string,
}

SEO.defaultProps = {
  title: null,
  description: null,
  image: null,
  contentType: "website",
}

const query = graphql`
  query {
    strapiGlobal {
      siteName
      favicon {
        localFile {
          publicURL
        }
      }
      defaultSeo {
        metaTitle
        metaDescription
        shareImage {
          localFile {
            publicURL
          }
        }
      }
    }
  }
`
