import React, { Component, Suspense } from "react"
import Loadable from "@loadable/component"

// Seo Component
import SEO from "./seo"

// Layout Components
import Topbar from "./Topbar"
// const Topbar = Loadable(() => import("./Topbar"))
const Footer = Loadable(() => import("./Footer"))
const Chat = Loadable(() => import("./Chat"))

//Import Icons
import FeatherIcon from "feather-icons-react"

/* import "../../assets/css/materialdesignicons.min.css" */
import "../../assets/css/colors/default.css"

import imgbg from "../../assets/images/account/bg.png"

const CustomDot = () => {
  return (
    <i>
      <FeatherIcon icon="arrow-up" className="icons" />
    </i>
  )
}

class Layout extends Component {
  componentDidMount() {
    let isMobile = window.innerWidth < 1000
    document.body.classList = ""
    document.getElementById("top-menu").classList.add("nav-light")
    document.getElementById("signUpButton").className = "btn btn-success"
    // document.getElementById("brandLogo").src = isMobile ? logodark : logolight
    window.addEventListener("scroll", this.scrollNavigation, true)
  }
  // Make sure to remove the DOM listener when the component is unmounted.
  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollNavigation, true)
  }

  scrollNavigation = () => {
    let isMobile = window.innerWidth < 1000
    var doc = document.documentElement
    var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0)
    if (top > 80) {
      document.getElementById("topnav").classList.add("nav-sticky")
      document.getElementById("signUpButton").className = "btn btn-primary"
    } else {
      document.getElementById("topnav").classList.remove("nav-sticky")
      document.getElementById("signUpButton").className = "btn btn-success"
    }
  }

  Loader = () => {
    return (
      <div id="preloader">
        <div id="status">
          <div className="spinner">
            <div className="double-bounce1"></div>
            <div className="double-bounce2"></div>
          </div>
        </div>
      </div>
    )
  }

  render() {
    return (
      <>
        <SEO seo={this.props.seo} />
        <Topbar />
        {this.props.children}
        <Chat />
        <Footer />
      </>
    )
  }
}

export default Layout
